import { Controller } from "stimulus"
import AxiosRequest from "../../utils/axios-request"
import Moment from 'moment'

export default class extends Controller
  InternalMovement: ->
    @uid = @.data.get 'uid'
    @id = @.data.get 'id'
    AxiosRequest("/admin/#{@uid}/leasing_contracts/stores", {item_id: @id}, 'get').then ({data}) =>
      option_stores = data.stores.map((e) => ("<option value='#{e.id}'>#{e.name}</option>"))
      Swal.fire({
        title: "Traslado interno",
        allowOutsideClick: false
        showCloseButton: true
        html:
          "
          <div class='uk-text-left'>
            <label for='stores' class='uk-form-label uk-label uk-text-lowercase'>Seleccione una tienda</label>
            <select name='stores' id='stores' class='uk-input uk-margin-auto-vertical'>
              <option value=''>Seleccione una opción</option>"+option_stores+"
            </select>
          </div>
          "
        focusConfirm: false,
        confirmButtonText: 'Trasladar'
        preConfirm: ->
          stores = document.getElementById('stores').value
          if stores == ''
            Swal.showValidationMessage('Seleccione una tienda')
      }).then (res) =>
        if res.value
          AxiosRequest("/admin/#{@uid}/inventories/movement", {id: @id, person_id: @uid, store_id: document.getElementById('stores').value}, 'get').then ({data}) =>
            if !! data
              Swal.fire({
                toast: true,
                position: 'top-end',
                icon: 'success',
                title: data.message,
                showConfirmButton: false,
                timer: 2500
              }).then ->
                window.location.reload()
