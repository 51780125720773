import {Controller} from "stimulus"
import SlimSelect from 'slim-select'
import Moment from 'moment'
import Lodash from 'lodash'

export default class extends Controller
  connect: ->
    @url = @.data.get 'url'
    @event = @.data.get 'event'
    @data_selected = @.data.get 'selected'
    new SlimSelect {
      select: '.select_multiple_advanced',
      placeholder: 'Seleccione opción.',
      searchText: 'Lo sentimos, no se ha encontrado lo que buscas',
      searchPlaceholder: 'Buscar'
      closeOnSelect: false
      ajax: (search, callback) =>
        if search.length < 3
          callback('Necesita mínimo 3 letras')
          return

        fetch("#{@url}.json?q=#{search}&date=#{@event}").then (response) =>
          response.json()
        .then (json) =>
          data = []
          i = 0
          while i < json.length
            data.push json[i]
            i++
          # filter _destroy
          tmp_items = JSON.parse @data_selected
          if tmp_items.length > 0
            if tmp_items.filter((e) => e._destroy == true).length > 0
              tmp_items = tmp_items.filter((e) => e._destroy == false)
          # return
          callback Lodash.differenceBy(data, tmp_items, 'iditem')
          return
        .catch (error) =>
          callback false
          return
    }
