import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
require 'trix'

import UiKit from 'uikit'
import UiIcons from 'uikit/dist/js/uikit-icons.min'

# Stimulus Controllers
import 'controllers/user'
# stimulus Controllers
import 'controllers/admin'
# Componentes de view components
import "../components"

UiKit.use(UiIcons)

window.Swal = require 'sweetalert2/dist/sweetalert2.min'

Rails.start()
Turbolinks.start()
ActiveStorage.start()
