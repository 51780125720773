csrfToken = (document) ->
  return document.querySelector('[name="csrf-token"]').content

export passCsrfToken = (document, axios) ->
  axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken(document)

export getMetaValue = (name) ->
  element = findElement document.head, "meta[name='#{name}']"
  return element.getAttribute 'content' if element

export findElement = (root, selector) ->
  if typeof root == 'string'
    selector = root
    root = document
  root.querySelector selector

export toArray = (value) ->
  if Array.isArray(value)
    return value
  else if Array.from
    return Array.from value
  else
    return [].slice.call value

export removeElement = (el) ->
  if el && el.parentNode
    el.parentNode.removeChild el

export insertAfter = (el, referenceNode) ->
  referenceNode.parentNode.insertBefore el, referenceNode.nextSibling
