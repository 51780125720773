import { Controller } from "stimulus"
import fetchOptions from '../../utils/fetch-options'
import Choices from 'choices.js'

export default class extends Controller
  @targets: ['master']

  setVictims: () ->
    { data } = this
    victims = data.get 'victim'
    url = data.get 'url'
    id = @masterTarget.value
    mapped_victims = victims.split ','
    fetchOptions "#{url}/#{if id then id else 'joker'}.json", {}, mapped_victims, true

    connect: () ->
      checker = parseInt @masterTarget.value
      @setVictims() if checker
