import { Controller } from "stimulus"
import axios from 'axios'

export default class extends Controller
  @targets: ['canvas', 'button']
  coord: { x: 0, y: 0 }
  drawing: false

  submit: ->
    image = @canvasTarget.toDataURL()
    csrfToken = document.querySelector("meta[name=csrf-token]").content

    try
      response = await axios.put(
        'save_signature.json',
        {image},
        headers: {'X-CSRF-Token': csrfToken}
      )
      Swal.fire(icon: 'success', title: 'Firma guardada').then =>
        @clear()
        window.location.replace(response.data.url)
    catch err
      Swal.fire(icon: 'error', title: 'Error al crear la firma', text: err.response.data?.message)

  clear: ->
    @canvasTarget.width = @canvasTarget.width
    @buttonTarget.disabled = true

  initContext: ->
    @ctx = @canvasTarget.getContext '2d'
    @ctx.strokeStyle = '#000000'
    @ctx.lineWidth = 2
    @ctx.lineCap = 'rounded'
    @ctx.beginPath()

  connect: ->
    @initContext()
    window.addEventListener 'resize', @resize

    # Eventos de Desktop
    document.addEventListener 'mousedown', @startDrawing
    document.addEventListener 'mouseup', @stopDrawing
    document.addEventListener 'mousemove', @drawSketch

    # Eventos de pantallas táctiles
    document.addEventListener 'touchend', @stopDrawing
    document.addEventListener 'touchmove', (eve) =>
      touch = eve.touches[0]
      mouseEvent = new MouseEvent 'mousedown', {
        clientX: touch.clientX,
        clientY: touch.clientY
      }
      @drawSketch(mouseEvent)
    document.addEventListener 'touchstart', (eve) =>
      touch = eve.touches[0]
      mouseEvent = new MouseEvent 'mousedown', {
        clientX: touch.clientX,
        clientY: touch.clientY
      }
      @startDrawing(mouseEvent)

  startDrawing: (eve) =>
    @drawing = true
    @buttonTarget.disabled = false
    @coord.x = eve.clientX - @canvasTarget.getBoundingClientRect().left
    @coord.y = eve.clientY - @canvasTarget.getBoundingClientRect().top

  drawSketch: (eve) =>
    return 0 unless @drawing

    @ctx.moveTo(@coord.x, @coord.y)
    @coord.x = eve.clientX - @canvasTarget.getBoundingClientRect().left
    @coord.y = eve.clientY - @canvasTarget.getBoundingClientRect().top
    @ctx.lineTo @coord.x, @coord.y
    @ctx.stroke()

  stopDrawing: (eve) => @drawing = false
