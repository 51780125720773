import axios from 'axios'

AxiosRequest = (url, data = {}, method = 'get') ->
  if method is 'post'
    axios.defaults.headers.post['X-CSRF-Token'] = document.querySelector("meta[name=csrf-token]").content;
  if method is 'delete'
    axios.defaults.headers.delete['X-CSRF-Token'] = document.querySelector("meta[name=csrf-token]").content;

  query = axios {
    url
    "#{if method is 'get' then 'params' else 'data'}": data
    method
  }
  query.catch (error) ->
    if error.response
      {response} = error
      Swal.fire {
        icon: 'error'
        title: response.data.message
        customClass: 'sweetalert-width-medium'
        html: "
          <ul class='uk-list uk-list-decimal uk-text-left'>
            <li class='uk-text-left'>#{ response.data.errors.join('</li><li>') }</li>
          </ul>
        "if response.data.errors
      }
    else if error.request
      console.log error.request
    else
      console.log 'Error', error.message
  query

export default AxiosRequest
