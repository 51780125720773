import {Controller} from "stimulus"
import SlimSelect from 'slim-select'

export default class extends Controller
  connect: ->
    new SlimSelect {
      select: '.select_multiple',
      placeholder: 'Seleccione opción.',
      searchText: 'Lo sentimos, no se ha encontrado lo que buscas',
      searchPlaceholder: 'Buscar',
      class: '.s-content',
      closeOnSelect: false
    }
