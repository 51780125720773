import { Controller } from "stimulus"
import AxiosRequest from "../../utils/axios-request"
import Moment from 'moment'

export default class extends Controller
  ReguestEdit: ->
    @uid = @.data.get 'uid'
    @id = @.data.get 'id'
    Swal.fire({
      title: "Solicitud modificación de contrato ##{@id}",
      allowOutsideClick: false
      showCloseButton: true
      html:
        "
        <div class='uk-text-left'>
          <label for='comment' class='uk-form-label uk-label uk-text-lowercase'>Comentario</label>
          <textarea class='swal2-input uk-margin-auto-vertical' id='comment' name='comment' rows='4' cols=50></textarea>
        </div>
        "
      focusConfirm: false,
      confirmButtonText: 'Envíar Solicitud'
      preConfirm: ->
        comment = document.getElementById('comment').value
        if comment == ''
          Swal.showValidationMessage('Comentario no puede estar vacio')
    }).then (res) =>
      if res.value
        request_contract = {
          contract_id: parseInt @id
          person_id: parseInt @uid
          comment: document.getElementById('comment').value
        }
        AxiosRequest("/admin/#{@uid}/request_contracts", {request_contract}, 'post').then ({data}) =>
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'success',
            title: data.message,
            showConfirmButton: false,
            timer: 2500
          })
