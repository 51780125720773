import {Controller} from "stimulus"
import SlimSelect from 'slim-select'

export default class extends Controller
  connect: ->
    new SlimSelect {
      select: 'select[multiple="multiple"]',
      placeholder: 'Seleccione opción.',
      searchText: 'Lo sentimos, no se ha encontrado lo que buscas',
      searchPlaceholder: 'Buscar'
    }
